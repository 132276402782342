"use client";
import { useRouter } from "@/lib/router-events";
import { signOut, useSession } from "next-auth/react";
import { useCallback, useEffect } from "react";
import { useFirstMountState } from "react-use";

type Props = {
  searchParams: {
    next: string;
  };
};

export default function SignoutPage({ searchParams }: Props) {
  const next = searchParams.next || "/auth";
  const router = useRouter();
  const firstMount = useFirstMountState();
  const session = useSession();
  const signout = useCallback(async () => {
    if (!session) {
      return;
    }
    await signOut({ redirect: false });
    router.push(next);
  }, [next, router, session]);

  useEffect(() => {
    if (firstMount) {
      signout();
    }
  }, [firstMount, signout]);
  if (!session) {
    return <h2>You are not signed in.</h2>;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-3xl font-bold">Signed out</h1>
        <p className="mt-2 text-sm text-gray-600">
          You have been signed out. Return to the <a href="/">home page</a>.
        </p>
      </div>
    </div>
  );
}
